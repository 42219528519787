<template>
  <feedback-section :progress-active="progressActive"
                    :button-description="lastUpdatedText"
                    class="assignment-type-section"
                    @save="save">
    <section class="assignment-type-section__sections-wrapper">
      <div class="post-form__section">
        <p class="post-form__section-title assignment-type-section__title">{{ $gettext('Change job category') }}</p>
        <sk-note v-if="isVatFreeCategory"
                 :text="note"
                 class="assignment-type-section__note" />
        <sk-select :description="categoryText"
                   :default-value="selectCategoryText"
                   :items-list="categories"
                   :ignore-categories="true"
                   :preselected-value="categoryId"
                   class="post-form__field subject__select post-form__field--half-width"
                   @csvaluechanged="setCategory" />
      </div>
    </section>
  </feedback-section>
</template>

<script>
  import {mapMutations, mapState, mapGetters} from 'vuex';
  import helpers from '@/helpers';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';

  export default {
    components: {
      'feedback-section': FeedbackSection
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('PostingStore/SubjectStore', [
        'getCategoryData'
      ]),
      ...mapState('PostingStore/SubjectStore', {
        categories: (state) => state.categoriesList || [],
        categoryId: (state) => state.category.toString(),
      }),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {}
      }),
      ...mapState('FeedbackStore', {
        lastUpdatedData: ({lastUpdatedData}) => lastUpdatedData?.job_updated_assignment_type,
        lastUpdatedJobData: ({lastUpdatedData}) => lastUpdatedData?.job_updated
      }),
      categoryText() { return this.$gettext('Category'); },
      selectCategoryText() { return this.$gettext('Select a category'); },
      lastUpdatedText() {
        return helpers.feedbackInfo.lastUpdatedText({
          context: this,
          lastUpdatedSectionData: this.lastUpdatedData,
          lastUpdatedJobData: this.lastUpdatedJobData,
        });
      },
      jobId() {
        return this.$route.params.id;
      },
      statementId() {
        return this.assignmentObj.statementId;
      },
      isVatFreeCategory() { return this.getCategoryData.vatFree || false; },
      note() {
        return this.$gettext('By choosing an MVA-fri category, you confirm that this assignment is outside VAT law. If required, we may request your accounting department for documentation regarding this.');
      }
    },
    methods: {
      ...mapMutations('PostingStore/SubjectStore', [
        'setCategory'
      ]),
      save() {
        this.progressActive = true;
        this.$store.dispatch('JobDecisionStore/updateJobStatement', {
          jobId: this.jobId,
          statementId: this.statementId
        })
          .then(() => {
            this.$store.dispatch('FeedbackStore/getLastUpdatedData', {
              jobId: this.jobId
            })
              .then(() => {
                this.progressActive = false;
              })
              .catch((error) => {
                this.progressActive = false;
                this.$store.commit('ModalStore/setModal', {
                  component: 'error-modal',
                  data: {
                    error
                  }
                });
              });
          })
          .catch(() => {
            this.progressActive = false;
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal'
            });
          });
      }
    },
    mounted() {
      if (this.assignmentObj.info.jobCategoryItem) {
        this.setCategory(this.assignmentObj.info.jobCategoryItem.id);
      }
    }
  };
</script>

<style scoped>
  .assignment-type-section__sections-wrapper {
    margin: 0 -15px;
  }

  .assignment-type-section__radios-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .assignment-type-section__radio {
    width: auto;
  }

  .assignment-type-section__title {
    width: 100%;
  }

  .assignment-type-section__note {
    margin: 0 15px 10px;
  }
</style>
